import React from 'react';
import classNames from 'classnames/bind';
import { useMediaQuery } from 'react-responsive';

import styles from './Section5.module.scss';
import mapPC from '../../assets/images/cuchara/map-pc.jpg';
import mapMO from '../../assets/images/cuchara/map-mo.jpg';
import stores from '../../assets/datas/stores.json';

const cx = classNames.bind(styles);

const Section5 = () => {
  const isSmallToTable = useMediaQuery({ query: '(max-width: 1040px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 734px)' });

  return (
    <div className={cx('container')}>
      <h1>LOCATIONS</h1>
      <div className={cx('mapWrapper')}>
        <div className={cx('map')}>
          <img src={isMobile ? mapMO : mapPC} alt="locations" />
        </div>
      </div>
      <div className={cx('tableWrapper')}>
        <div className={cx('table')}>
          {!isSmallToTable && (
            <div className={cx(['row', 'tableHeader'])}>
              <div className={cx(['column', 'name'])}>매장명</div>
              <div className={cx(['column', 'phone'])}>전화번호</div>
              <div className={cx(['column', 'time'])}>영업시간</div>
              <div className={cx('column')}>주소</div>
            </div>
          )}
          {stores.map((store, index) => (
            <div key={index} className={cx('row')}>
              <div className={cx(['column', 'name'])}>{store.name}</div>
              <div className={cx(['column', 'phone'])}>{store.phone}</div>
              <div className={cx(['column', 'eachTime'])}>
                <div className={cx('label')}>{store.time[0].name}</div>{' '}
                {store.time[0].value}
              </div>
              <div className={cx(['column', 'eachTime'])}>
                <div className={cx('label')}>{store.time[1].name}</div>{' '}
                {store.time[1].value}
              </div>
              <div className={cx('column')}>{store.address}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Section5;
